.con[data-v-2003edeb] {
  width: 100%;
  height: 100%;
}
.left-title[data-v-2003edeb] {
  line-height: 45px;
  font-size: 20px;
  font-weight: 550;
  color: #409eff;
}
[data-v-2003edeb] .main-left-tree-content {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #409eff;
}
